@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
    @apply bg-blue-gray-100 text-blue-900 box-border;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar:horizontal {
    height: 8px;
}


::-webkit-scrollbar-thumb {
    @apply bg-xconnections-200 rounded-xl;
}

.task > ::-webkit-scrollbar-thumb {
    @apply bg-blue-500 rounded-xl;
}